import React from "react";
import { Form, ContactBanner } from "../../components";
const Transportation = () => {
  return (
    <>
      <div>
        <ContactBanner />
        <Form />
      </div>
    </>
  );
};

export default Transportation;
